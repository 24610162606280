import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware/helpers";

export interface ProductProperties {
  groupName: string;
  groupNameSortIndex: number;
  properties: ProductProperty[];
}

type TrackingItemCategories =
  | {
      item_category: string;
    }
  | {
      item_category: string;
      item_category2: string;
    }
  | {
      item_category: string;
      item_category2: string;
      item_category3: string;
    }
  | {
      item_category: string;
      item_category2: string;
      item_category3: string;
      item_category4: string;
    }
  | {
      item_category: string;
      item_category2: string;
      item_category3: string;
      item_category4: string;
      item_category5: string;
    };

type TrackingItem = (
  | {
      item_id: string;
      item_name?: string;
    }
  | {
      item_id?: string;
      item_name: string;
    }
) & {
  price: number | undefined;
  discount: number;
  quantity: number;
  item_variant?: string;
} & TrackingItemCategories & {
    affiliation?: string;
    promotion_id?: string;
    promotion_name?: string;
    item_list_id?: string;
    item_list_name?: string;
  };

const ARCHIVE_CATEGORY_ID = "0190cb221f9a70e0a2e664b3905631e3";

export async function useTrackingHelper() {
  const { apiClient } = useShopwareContext();
  const { locale } = useI18n();

  const { data: categoryMap } = await useFetch("/api/internal/categories", {
    deep: true,
  });

  async function productToTrackingItem(
    product: Schemas["Product"],
    options: Partial<
      Pick<
        TrackingItem,
        | "quantity"
        | "affiliation"
        | "promotion_id"
        | "promotion_name"
        | "item_list_id"
        | "item_list_name"
      >
    > = {},
  ): Promise<TrackingItem> {
    const { price } = useProductPrice(toRef(product));

    const categotyId = product.categoryIds?.find(
      (x) => x != ARCHIVE_CATEGORY_ID,
    );

    const categoryStack = categotyId
      ? Object.values(categoryMap.value?.[categotyId]?.[locale.value] ?? {})
      : [];

    const categories: Record<string, string> = {};
    if (categoryStack.length > 1) {
      // drop the 'Home' category and reverse the rest
      const reversedCategories = categoryStack.slice(1).reverse();
      for (let i = 1; i <= reversedCategories.length; i++) {
        categories[`item_category${i > 1 ? i : ""}`] =
          reversedCategories[i - 1]!;
      }
    }

    return {
      item_id: product.productNumber,
      item_name: getTranslatedProperty(product, "name"),
      price: price.value?.unitPrice,
      discount: price.value?.listPrice?.percentage ?? 0,
      item_variant: product.customFields?.variantType,
      quantity: 1,
      ...(categories as unknown as TrackingItemCategories),
      ...options,
    };
  }

  type TrackingItemOptions = Partial<
    Pick<
      TrackingItem,
      | "quantity"
      | "affiliation"
      | "promotion_id"
      | "promotion_name"
      | "item_list_id"
      | "item_list_name"
    >
  >;

  async function lineItemsToTrackingItems(
    lineItems: (Schemas["LineItem"] | Schemas["OrderLineItem"])[],
    options: TrackingItemOptions = {},
  ) {
    if (lineItems.length === 0) return [];

    const ids = lineItems
      .filter((x) => x.type === "product")
      .map((x) => x.referencedId!);

    const { data } = await apiClient.invoke("readProduct post /product", {
      body: { ids },
    });

    return Promise.all(
      data.elements?.map((x) =>
        productToTrackingItem(x, {
          ...options,
          quantity: lineItems.find((y) => y.referencedId === x.id)?.quantity,
        }),
      ) ?? [],
    );
  }

  return {
    productToTrackingItem,
    lineItemsToTrackingItems,
  };
}
